<template>
   <div class="mt-10">
      <v-img
         src="@/assets/ComoComecar/como_comecar.svg"
         :width="ehVersaoDesktop ? 400 : 300"
         class="image-margin-auto"
      />
      <div class="mt-12">
         <v-row class="mt-12 mt-md-1 pt-12 pt-md-1">
            <v-col cols="1" v-if="ehVersaoDesktop" />
            <v-col>
               <div class="custom-container">
                  <div class="d-flex justify-center align-center numero-card">1</div>
                  <div
                     class="box-texto texto-container d-flex justify-center align-center texto-tamanho-como-comecar"
                     v-if="ehVersaoDesktop"
                  >
                     Agende sua avaliação com a Health Coach pela plataforma
                     <a class="ml-1 mr-1" @click="abrirLinkConexaPacientes"><u class="cor-link">Conexa Saúde.</u></a>
                     Na opção Nutricionista, busque pelo profissional 01 Health Coach
                  </div>
                  <div class="box-texto texto-container align-center texto-tamanho-como-comecar" v-else>
                     Agende sua avaliação com a Health Coach pela plataforma
                     <a class="ml-1 mr-1" @click="abrirLinkConexaPacientes"><u class="cor-link"> Conexa Saúde. </u></a>
                     Na opção Nutricionista, busque pelo profissional 01 Health Coach
                  </div>
               </div>
            </v-col>
         </v-row>
         <div class="mt-12 pt-6 pb-6">
            <v-row justify="center" align="center">
               <v-col>
                  <v-img
                     src="@/assets/ComoComecar/dispositivos.svg"
                     :width="ehVersaoDesktop ? 600 : 300"
                     class="image-margin-auto"
                  />
               </v-col>
               <v-col v-if="ehVersaoDesktop" md="2" class="image-laterais-direita-baixo">
                  <v-img
                     src="@/assets/ComoComecar/quadrados_laterais_direita_baixo.svg"
                     :width="ehVersaoDesktop ? 150 : 70"
                     class="imagem-over-left image-margin-auto"
                  />
               </v-col>
            </v-row>
         </div>
         <v-row class="mt-12 mt-md-1 pt-12 pt-md-1 mb-12">
            <v-col cols="2" v-if="ehVersaoDesktop" />
            <v-col>
               <div class="custom-container">
                  <div class="d-flex justify-center align-center numero-card">2</div>
                  <div class="box-texto texto-container-segundo texto-tamanho-como-comecar">
                     Participe do grupo de Whatsapp para interagir com os demais participantes, trocar experiências,
                     receber as comunicações do programa, receitas, dicas e esclarecer dúvidas. Você poderá postar fotos
                     das refeições e compartilhar suas práticas de exercícios.
                  </div>
               </div>
            </v-col>
         </v-row>
         <div class="mt-6 pt-12 pb-6 pt-md-1">
            <v-row justify="center" align="center">
               <v-col cols="12" v-if="ehVersaoDesktop" md="2" class="image-laterais-esquerda">
                  <v-img
                     src="@/assets/ComoComecar/quadrados_laterais_esquerda.svg"
                     :width="ehVersaoDesktop ? 150 : 70"
                     class="imagem-over-left image-margin-auto"
                  />
               </v-col>
               <v-col v-if="ehVersaoDesktop" />
               <v-col cols="7" md="7" class="image-prints-whats">
                  <v-img
                     src="@/assets/ComoComecar/prints_whats.svg"
                     :width="ehVersaoDesktop ? 600 : 450"
                     class="image-margin-auto"
                  />
               </v-col>
               <v-col cols="5" md="4" class="pr-6">
                  <v-img
                     src="@/assets/ComoComecar/dispositivo.svg"
                     :width="ehVersaoDesktop ? 400 : 450"
                     class="imagem-over-rigth image-margin-auto"
                  />
               </v-col>
            </v-row>
         </div>
         <v-row class="mt-6 mt-md-1 pb-6">
            <v-col cols="3" v-if="ehVersaoDesktop" />
            <v-col>
               <div class="custom-container">
                  <div class="d-flex justify-center align-center numero-card">3</div>
                  <div
                     class="box-texto texto-container-terceiro d-flex justify-center align-center texto-tamanho-como-comecar"
                     v-if="ehVersaoDesktop"
                  >
                     Após inscrito, toda sexta-feira, você irá receber a planilha de metas da próxima semana.
                  </div>
                  <div class="box-texto texto-container-terceiro align-center texto-tamanho-como-comecar" v-else>
                     Após inscrito, toda sexta-feira, você irá receber a planilha de metas da próxima semana.
                  </div>
               </div>
            </v-col>
         </v-row>
         <div class="mt-12 pt-12 pb-6 pt-md-1">
            <v-row justify="center" align="center">
               <v-col v-if="ehVersaoDesktop" />
               <v-col cols="12" md="6">
                  <v-img
                     src="@/assets/ComoComecar/conexa_equilibrio_tablet.svg"
                     :width="ehVersaoDesktop ? 450 : 250"
                     class="imagem-over-left image-margin-auto"
                  />
               </v-col>
               <v-col md="2" v-if="ehVersaoDesktop">
                  <v-img
                     src="@/assets/ComoComecar/quadrados_laterais_direita_baixo.svg"
                     :width="ehVersaoDesktop ? 150 : 70"
                     class="image-margin-auto"
                  />
               </v-col>
            </v-row>
         </div>
         <div class="mt-12 d-flex justify-end" v-if="ehVersaoDesktop">
            <img src="@/assets/rectangle_orange.svg" :width="ehVersaoDesktop ? 350 : 150" />
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: 'ComoComecar',
   computed: {
      ehVersaoDesktop() {
         return this.$vuetify.breakpoint.mdAndUp;
      }
   },
   methods: {
      abrirLinkConexaPacientes() {
         window.open('https://paciente.conexasaude.com.br/login', '_blank');
      }
   }
};
</script>
