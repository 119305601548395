<template>
   <v-navigation-drawer app temporary v-model="drawer">
      <div class="text-center mt-6">
         <a href="/" target="_parent">
            <img src="@/assets/logo.svg" height="60" />
         </a>
      </div>
      <v-list>
         <v-list-item-group color="primary">
            <v-list-item v-for="(item, i) in menu" :key="i">
               <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
               </v-list-item-content>
            </v-list-item>
            <v-list-item>
               <v-list-item-content>
                  <v-btn
                     outlined
                     color="button"
                     class="mx-lg-1 btn-text-bolder"
                     id="btn-ja-sou-inscrito"
                     @click="abrirDialogJaSouInscrito"
                  >
                     Já sou inscrito
                  </v-btn>
               </v-list-item-content>
            </v-list-item>
         </v-list-item-group>
      </v-list>
   </v-navigation-drawer>
</template>

<script>
import eventBus from '@/event-bus';

export default {
   name: 'LeftDrawer',
   data: () => ({
      drawer: false,
      menu: [
         {
            text: 'O PROGRAMA',
            url: 'https://conteudo.psicologiaviva.com.br/psicologo-cadastro/'
         },
         { text: 'ESPECIALISTAS', url: 'https://blog.psicologiaviva.com.br/' },
         {
            text: 'DESAFIOS',
            url: 'https://corporate.psicologiaviva.com.br/'
         },
         {
            text: 'BENEFÍCIOS',
            url: 'https://consultorioonline.psicologiaviva.com.br/cadastro/'
         },
         {
            text: 'DÚVIDAS',
            url: 'https://consultorioonline.psicologiaviva.com.br/cadastro/'
         },
         {
            text: 'CONTATO',
            url: 'https://consultorioonline.psicologiaviva.com.br/cadastro/'
         }
      ]
   }),
   mounted() {
      eventBus.$on('left-drawer-open', this.open);
   },
   beforeDestroy() {
      eventBus.$off('left-drawer-open');
   },
   methods: {
      open() {
         this.drawer = true;
      },
      abrirDialogJaSouInscrito() {
         eventBus.$emit('abrir-dialog-ja-sou-inscrito');
      }
   }
};
</script>
