<template>
   <div class="bg-color-footer pb-6 pt-8">
      <v-row class="ml-md-12">
         <v-col>
            <v-img
               src="@/assets/logo_footer.svg"
               :width="ehVersaoDesktop ? 160 : 140"
               :class="ehVersaoDesktop ? '' : 'image-margin-auto'"
            />
         </v-col>
      </v-row>
      <v-row class="text-center white--text mt-2 mt-md-0 ml-md-12">
         <v-col cols="12" md="2" :class="{ 'd-flex': true, 'justify-cente': !ehVersaoDesktop }">
            <v-btn icon color="white" @click="abrirUrl('https://www.facebook.com/conexasaude/')">
               <v-icon> bi-facebook </v-icon>
            </v-btn>
            <v-btn icon color="white" @click="abrirUrl('https://www.linkedin.com/company/conexa-saude/')">
               <v-icon> bi-linkedin </v-icon>
            </v-btn>
            <v-btn icon color="white" @click="abrirUrl('https://www.instagram.com/conexa_saude/')">
               <v-icon> bi-instagram </v-icon>
            </v-btn>
         </v-col>
         <v-col cols="12" md="8" class="cor-texto-footer">
            <div>&copy; {{ anoAtual }}. Grupo Conexa. Todos os direitos reservados.</div>
         </v-col>
         <v-col v-if="ehVersaoDesktop" />
      </v-row>
   </div>
</template>

<script>
export default {
   name: 'Footer',
   computed: {
      ehVersaoDesktop() {
         return this.$vuetify.breakpoint.mdAndUp;
      },
      anoAtual() {
         return new Date().getFullYear();
      }
   },
   methods: {
      abrirUrl(url) {
         window.open(url, '_blank');
      }
   }
};
</script>
<style scoped>
.bg-color-footer {
   background-color: #343434;
}
.cor-texto-footer {
   color: #999999;
}
</style>
