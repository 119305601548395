<template>
   <div>
      <v-img src="@/assets/ConteudoPrograma/fundo_conteudo_programa.svg">
         <div
            class="secondary--text text-lg-h4 text-sm-body-2"
            :style="ehVersaoDesktop ? 'margin-top: 6%' : 'margin-top: 20%'"
         >
            <v-img src="@/assets/Contato/contato.svg" :width="ehVersaoDesktop ? 300 : 200" style="margin: auto" />
         </div>
         <div style="margin-top: 5%">
            <h1 class="white--text text-center">Ficou com dúvidas? Fale com a gente</h1>
         </div>
         <div style="margin-top: 5%" class="pb-12">
            <div class="secondary--text text-lg-h2 d-flex justify-space-around flex-wrap mb-4">
               <v-card
                  color="white"
                  rounded="lg"
                  :width="ehVersaoDesktop ? 240 : 240"
                  :height="ehVersaoDesktop ? 190 : 220"
               >
                  <v-card-title>
                     <img src="@/assets/Contato/whats.svg" :width="ehVersaoDesktop ? 60 : 80" style="margin: auto" />
                  </v-card-title>
                  <v-card-text class="text-center mt-4">
                     <h3>HEALTH COACH</h3>
                     <p class="mt-4">(21) 9.9455-7364</p>
                  </v-card-text>
               </v-card>
               <v-card
                  color="white"
                  rounded="lg"
                  :width="ehVersaoDesktop ? 240 : 240"
                  :height="ehVersaoDesktop ? 190 : 220"
                  :class="ehVersaoDesktop ? '' : 'mt-8'"
               >
                  <v-card-title>
                     <img
                        src="@/assets/Contato/phone_call.svg"
                        :width="ehVersaoDesktop ? 60 : 80"
                        style="margin: auto"
                     />
                  </v-card-title>
                  <v-card-text class="text-center mt-4">
                     <h3>SUPORTE 24 HORAS</h3>
                     <p class="mt-4">(21) 9.9625-5086</p>
                  </v-card-text>
               </v-card>
               <v-card
                  color="white"
                  rounded="lg"
                  :width="ehVersaoDesktop ? 240 : 240"
                  :height="ehVersaoDesktop ? 190 : 220"
                  :class="ehVersaoDesktop ? '' : 'mt-8'"
               >
                  <v-card-title>
                     <img src="@/assets/Contato/mail.svg" :width="ehVersaoDesktop ? 60 : 80" style="margin: auto" />
                  </v-card-title>
                  <v-card-text class="text-center mt-4">
                     <h3>EMAIL</h3>
                     <p class="mt-4">equilibrio@conexasaude.com.br</p>
                  </v-card-text>
               </v-card>
            </div>
         </div>
      </v-img>
   </div>
</template>

<script>
export default {
   name: 'Contato',
   computed: {
      ehVersaoDesktop() {
         return this.$vuetify.breakpoint.mdAndUp;
      }
   }
};
</script>
