<template>
   <v-app-bar app elevate-on-scroll color="white" class="px-0">
      <v-container class="d-flex pa-0">
         <div class="d-flex align-center">
            <img src="@/assets/logo.svg" :height="$vuetify.breakpoint.mdAndUp ? 49 : 30" />
         </div>

         <v-spacer />

         <div v-if="$vuetify.breakpoint.mdAndUp" class="py-4">
            <template v-for="(item, i) in menu">
               <v-btn
                  :key="i"
                  text
                  rounded
                  color="primary"
                  class="mx-lg-1 texto-tamanho"
                  :href="item.url"
                  target="_self"
               >
                  {{ item.text }}
               </v-btn>
            </template>
            <v-btn
               outlined
               color="button"
               class="mx-lg-1 btn-text-bolder"
               id="btn-ja-sou-inscrito"
               @click="abrirDialogJaSouInscrito"
            >
               Já sou inscrito
            </v-btn>
         </div>
         <div v-else class="py-4">
            <v-app-bar-nav-icon @click="openDrawer" />
         </div>
      </v-container>
      <DialogSouInscrito />
   </v-app-bar>
</template>

<script>
import eventBus from '@/event-bus';
import DialogSouInscrito from '@/components/DialogSouInscrito';

export default {
   name: 'Header',
   components: { DialogSouInscrito },
   computed: {
      menu() {
         return [
            {
               text: 'O PROGRAMA',
               url: '#o-programa'
            },
            { text: 'ESPECIALISTAS', url: '#especialistas' },
            {
               text: 'DESAFIOS',
               url: '#desafios'
            },
            {
               text: 'BENEFÍCIOS',
               url: '#beneficios'
            },
            {
               text: 'DÚVIDAS',
               url: '#duvidas'
            },
            {
               text: 'CONTATO',
               url: '#contato'
            }
         ];
      }
   },
   methods: {
      abrirDialogJaSouInscrito() {
         eventBus.$emit('abrir-dialog-ja-sou-inscrito');
      },
      openDrawer() {
         eventBus.$emit('left-drawer-open');
      }
   }
};
</script>
<style scoped>
.texto-tamanho {
   font-size: 13px;
}
</style>
