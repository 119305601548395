<template>
   <div class="bg-color-desafios pb-12 pt-12 pt-md-0">
      <div class="pt-12">
         <v-img src="@/assets/desafios.svg" :width="ehVersaoDesktop ? 300 : 200" class="image-margin-auto" />
      </div>
      <div class="pt-12 text-center">
         <h2 class="text-header-color">Quem participa ainda pode ganhar prêmios com a pontuação dos desafios</h2>
         <p class="custom-text-color-desafios pt-12 text-h6 container-text">
            Você vai receber os desafios no
            <a class="white--text">grupo do Whatsapp.</a>
         </p>
         <p class="custom-text-color-desafios text-h6">Preencha toda semana para ter mais chances de ganhar!</p>
      </div>
   </div>
</template>

<script>
export default {
   name: 'Desafios',
   computed: {
      ehVersaoDesktop() {
         return this.$vuetify.breakpoint.mdAndUp;
      }
   }
};
</script>
