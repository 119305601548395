<template>
   <div class="pt-12 pb-8 cor-fundo-pagina-especialista">
      <v-img src="@/assets/Especialistas/especialistas.svg" :width="ehVersaoDesktop ? 400 : 300" style="margin: auto" />
      <div class="text-lg-h2 d-flex justify-space-around flex-wrap pt-12 mb-12">
         <div>
            <div class="custom-container-especialista">
               <img src="@/assets/Especialistas/danielle.svg" :width="ehVersaoDesktop ? 300 : 200" />
               <span
                  class="white--text text-body-2 d-flex justify-center align-self-center justify-center pt-2 pb-2 nome-especialista"
               >
                  Danielle Toledo
               </span>
            </div>
            <div class="text-h6 text-center mt-6 mt-md-2 font-weight-bold">Health Coach</div>
         </div>
         <div :class="!ehVersaoDesktop ? 'mt-4' : ''">
            <div class="custom-container-especialista">
               <img src="@/assets/Especialistas/sara.svg" :width="ehVersaoDesktop ? 300 : 200" />
               <span
                  class="white--text text-body-2 d-flex justify-center align-self-center justify-center pt-2 pb-2 nome-especialista"
               >
                  Sara Singer
               </span>
            </div>
            <div class="text-h6 text-center mt-4 mt-md-2 font-weight-bold">Health Coach</div>
         </div>
         <div :class="!ehVersaoDesktop ? 'mt-4' : ''">
            <div class="custom-container-especialista">
               <img src="@/assets/Especialistas/gabi.svg" :width="ehVersaoDesktop ? 300 : 200" />
               <span
                  class="white--text text-body-2 d-flex justify-center align-self-center justify-center pt-2 pb-2 nome-especialista"
               >
                  Gabi Cavalcante
               </span>
            </div>

            <div class="text-h6 text-center mt-6 mt-md-2 font-weight-bold">Health Coach</div>
         </div>
      </div>
      <div class="text-center pt-6">
         <h1 class="secondary--text">E convidados: psicólogos, educadores físicos e muito mais</h1>
      </div>
   </div>
</template>

<script>
export default {
   name: 'Especialistas',
   computed: {
      ehVersaoDesktop() {
         return this.$vuetify.breakpoint.mdAndUp;
      }
   }
};
</script>
