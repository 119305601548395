<template>
   <v-app>
      <LeftDrawer />
      <Header />

      <v-main>
         <router-view />
      </v-main>

      <Footer />
   </v-app>
</template>

<script>
import Header from '@/components/Header';
import LeftDrawer from '@/components/Layout/LeftDrawer.vue';
import Footer from './components/Footer.vue';

export default {
   name: 'App',
   components: { Header, LeftDrawer, Footer }
};
</script>
<style lang="scss">
@import './scss/custom.scss';
</style>
