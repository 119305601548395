<template>
   <v-row class="footer-programa-conteudo">
      <v-col cols="12" sm="7">
         <img src="@/assets/OPrograma/o_programa.svg" />
      </v-col>
      <v-col cols="12" sm="3" class="mx-4 mx-md-0">
         <div class="my-12">
            <div>
               <img src="@/assets/OPrograma/o_programa_label_button.svg" />
            </div>
            <div class="mt-12 text-left">
               <h1 class="secondary--text">Gerencie hábitos e mude de vida</h1>
               <div>
                  <p class="texto-tamanho">
                     O <span class="lighsecondary--text">Conexa Equilíbrio</span> é um programa de Gestão de Hábitos de
                     Saúde que promove e faz a manutenção de hábitos de vida saudáveis no dia a dia de colaboradores.
                     Trazendo benefícios para você e para seu ambiente de trabalho!
                  </p>
                  <p class="mt-16 texto-tamanho">
                     Utilizando uma metodologia exclusiva e acompanhamento de um
                     <span class="lighsecondary--text">Health Coach</span>, o programa auxilia o participante a
                     silenciar hábitos negativos e criar hábitos saudáveis causando uma mudança verdadeira e sustentável
                     no seu estilo de vida.
                  </p>
               </div>
            </div>
         </div>
      </v-col>
   </v-row>
</template>

<script>
export default {
   name: 'OPrograma'
};
</script>
<style scoped>
.footer-programa-conteudo {
   margin-bottom: -3%;
}
.texto-tamanho {
   font-size: 15px;
}
</style>
