<template>
   <div>
      <v-img src="@/assets/SubHeader/fundo.svg">
         <div
            class="white--text text-lg-h6 text-sm-body-2"
            :style="$vuetify.breakpoint.mdAndUp ? 'margin-top: 15%' : 'margin-top: 20%'"
         >
            <v-img
               src="@/assets/SubHeader/conexa_equilibrio.svg"
               :width="$vuetify.breakpoint.mdAndUp ? 600 : 180"
               style="margin: auto"
            />
            <div class="text-center">
               <p class="mt-4">Crie uma rotina de hábitos saudáveis com nossos especialistas</p>
               <v-btn
                  v-if="false"
                  class="btn-text-bolder button--text pl-12 pr-12"
                  large
                  @click="abrirFormularioInscricao"
               >
                  Inscreva-se
               </v-btn>
               <p class="mt-6 mt-md-12">Saiba mais</p>
               <v-btn icon href="#programa">
                  <v-icon color="white">bi-chevron-down</v-icon>
               </v-btn>
            </div>
            <div class="d-flex mb-2 mb-md-0">
               <img
                  src="@/assets/logo_conexa.svg"
                  :width="$vuetify.breakpoint.mdAndUp ? 120 : 80"
                  class="ml-2 ml-md-12"
               />
               <img src="@/assets/logo_imed.svg" :width="$vuetify.breakpoint.mdAndUp ? 120 : 80" class="ml-4 ml-md-4" />
               <img src="@/assets/logo_pv.svg" :width="$vuetify.breakpoint.mdAndUp ? 120 : 80" class="ml-4 ml-md-4" />
            </div>
         </div>
      </v-img>
   </div>
</template>

<script>
export default {
   name: 'Subheader',
   methods: {
      abrirFormularioInscricao() {
         window.open('https://forms.office.com/r/tcZPqj4s5h', '_blank');
      }
   }
};
</script>
