<template>
   <div>
      <SubHeader />
      <OPrograma id="o-programa" />
      <ConteudoPrograma id="programa" />
      <Especialistas id="especialistas" />
      <ComoComecar />
      <Beneficios id="beneficios" />
      <Depoimentos />
      <Desafios id="desafios" />
      <Duvidas id="duvidas" />
      <Contato id="contato" />
   </div>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import OPrograma from '@/components/Informacoes/OPrograma.vue';
import ConteudoPrograma from '../components/Informacoes/ConteudoPrograma.vue';
import Especialistas from '../components/ConteudoPrograma/Especialistas.vue';
import ComoComecar from '../components/Informacoes/ComoComecar.vue';
import Beneficios from '../components/Informacoes/Beneficios.vue';
import Depoimentos from '../components/ConteudoPrograma/Depoimentos.vue';
import Desafios from '../components/Informacoes/Desafios.vue';
import Duvidas from '../components/Informacoes/Duvidas.vue';
import Contato from '../components/Informacoes/Contato.vue';

export default {
   name: 'Home',
   components: {
      SubHeader,
      OPrograma,
      ConteudoPrograma,
      Especialistas,
      ComoComecar,
      Beneficios,
      Depoimentos,
      Desafios,
      Duvidas,
      Contato
   }
};
</script>
