import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import VueGtm from '@gtm-support/vue2-gtm';

Vue.use(VueRouter);

const id = 'GTM-K48KZ82';

Vue.use(VueGtm, {
   id,
   defer: true,
   enabled: false,
   debug: false,
   vueRouter: router
});

const routes = [
   {
      path: '/',
      name: 'Home',
      component: Home
   }
];

const router = new VueRouter({
   mode: 'hash',
   routes
});

export default router;
