<template>
   <div class="pt-12 bg-color">
      <v-img src="@/assets/Beneficios/titulo.svg" :width="ehVersaoDesktop ? 300 : 200" class="image-margin-auto" />
      <v-row class="pt-12">
         <v-col cols="12" md="6" class="custom-container-moca">
            <v-img src="@/assets/Beneficios/moca_escrevendo.svg" class="image-margin-auto" contain />
         </v-col>
         <!-- TO DO SE FOR TENTAR VOLTAR COM A DIV FLUTUANTE USAR ESSA CLASSE -->
         <!-- TO DO VOLTAR A PARTE FLUTUANTE FLUTUANDO DNV VOLTAR ISSO :class="ehVersaoDesktop ? 'dados-flutuantes' : ''"  -->
         <v-col cols="12" md="6">
            <div class="div-flutuante pa-12">
               <v-row justify="center" align="center">
                  <v-col cols="12" md="12" class="d-flex align-center justify-start flex-wrap">
                     <img src="@/assets/check.svg" contain width="30" />
                     <span class="ml-2 text-caption text-sm-subtitle-1">Proporciona mais qualidade de vida</span>
                  </v-col>
                  <v-col cols="12" md="12" class="d-flex align-center justify-start">
                     <img src="@/assets/check.svg" contain width="30" />
                     <span class="ml-2 text-caption text-sm-subtitle-1">Conscientiza na prevenção de doenças</span>
                  </v-col>
                  <v-col cols="12" md="12" class="d-flex align-center justify-start">
                     <img src="@/assets/check.svg" contain width="30" />
                     <span class="ml-2 text-caption text-sm-subtitle-1">Melhora na qualidade do sono</span>
                  </v-col>
                  <v-col cols="12" md="12" class="d-flex align-center justify-start">
                     <img src="@/assets/check.svg" contain width="30" />
                     <span class="ml-2 text-caption text-sm-subtitle-1">
                        Ajuda a controlar a ansiedade e diminuir o estresse
                     </span>
                  </v-col>
                  <v-col cols="12" md="12" class="d-flex align-center justify-start">
                     <img src="@/assets/check.svg" contain width="30" />
                     <span class="ml-2 text-caption text-sm-subtitle-1">
                        Traz para a rotina menos medicações, cirurgias e hospitalizações
                     </span>
                  </v-col>
                  <v-col cols="12" md="12" class="d-flex align-center justify-start">
                     <img src="@/assets/check.svg" contain width="30" />
                     <span class="ml-2 text-caption text-sm-subtitle-1">Reduz risco de doenças crônicas</span>
                  </v-col>
               </v-row>
            </div>
         </v-col>
      </v-row>
   </div>
</template>

<script>
export default {
   name: 'Beneficios',
   computed: {
      ehVersaoDesktop() {
         return this.$vuetify.breakpoint.mdAndUp;
      }
   }
};
</script>
