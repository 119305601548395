<template>
   <v-dialog
      v-model="dialog"
      scrollable
      persistent
      :hide-overlay="!$vuetify.breakpoint.mdAndUp"
      :max-width="$vuetify.breakpoint.mdAndUp ? 315 : 315"
      transition="dialog-bottom-transition"
      @keydown.esc="fechar"
   >
      <v-card class="dialog-estilo">
         <v-card-text>
            <v-row class="mt-4">
               <v-col cols="12">
                  <v-btn
                     outlined
                     color="button"
                     class="mx-lg-1 btn-text-bolder"
                     id="btn-ja-sou-inscrito"
                     block
                     @click="agendarSessao"
                  >
                     Agendar sua sessão
                  </v-btn>
               </v-col>
               <v-col cols="12">
                  <v-btn
                     outlined
                     color="button"
                     class="mx-lg-1 btn-text-bolder"
                     id="btn-ja-sou-inscrito"
                     block
                     @click="abrirFormularioInscricao"
                  >
                     Registrar desafios
                  </v-btn>
               </v-col>
            </v-row>
         </v-card-text>
         <v-card-actions>
            <v-spacer />
            <v-btn text color="secondary" @click="fechar"> Fechar </v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>
<script>
import eventBus from '@/event-bus';
export default {
   name: 'DialogSouInscrito',
   data: () => ({
      dialog: false
   }),
   mounted() {
      eventBus.$on('abrir-dialog-ja-sou-inscrito', this.abrir);
   },
   beforeDestroy() {
      eventBus.$off('abrir-dialog-ja-sou-inscrito');
   },
   methods: {
      fechar() {
         this.dialog = false;
      },
      abrir() {
         this.dialog = true;
      },
      agendarSessao() {
         window.open('https://conexasaude.com.br/login', '_blank');
      },
      abrirFormularioInscricao() {
         window.open(
            'https://forms.office.com/Pages/ResponsePage.aspx?id=EMmDZbCuLUG-mSopnInz72rySPup6sBOl4H6PGDOf5RUMFo0SzBWQzcxUTkzQVlSUTZLWUIwU0E1Vi4u',
            '_blank'
         );
         this.dialog = false;
      }
   }
};
</script>
<style scoped>
.dialog-estilo {
   background-color: white;
}
</style>
