<template>
   <div class="bg-color-duvidas pb-12 pt-12">
      <div class="pt-12">
         <v-img src="@/assets/duvidas.svg" :width="ehVersaoDesktop ? 300 : 200" class="image-margin-auto" />
      </div>
      <div class="pt-12 pb-12 text-center">
         <v-expansion-panels class="custom-expansion-panel" accordion tile flat>
            <v-expansion-panel class="expansion-painel-border">
               <v-expansion-panel-header class="white--text" color="#343434">
                  <template v-slot:actions>
                     <v-icon color="white" large> $expand </v-icon>
                  </template>
                  <div class="body-2 text-md-h6">O que é o programa Conexa Equilíbrio?</div>
               </v-expansion-panel-header>
               <v-expansion-panel-content class="conteudo-expasion-papo-reto-maternidade" eager color="#343434">
                  <div class="white--text text-left text-caption text-md-subtitle-1">
                     <span>
                        É um programa de gerenciamento de hábitos de saúde onde seu coach criará um plano personalizado
                        junto a você, que irá ajudá-lo a alcançar seus objetivos de saúde. Espere consultas regulares de
                        vídeo/telefone e motivação diária através de mensagens e materiais educativos.
                     </span>
                  </div>
               </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="expansion-painel-border">
               <v-expansion-panel-header class="white--text" color="#343434">
                  <template v-slot:actions>
                     <v-icon color="white" large> $expand </v-icon>
                  </template>
                  <div class="body-2 text-md-h6">O que é um Health Coach?</div>
               </v-expansion-panel-header>
               <v-expansion-panel-content class="conteudo-expasion-papo-reto-maternidade" eager color="#343434">
                  <div class="white--text text-left text-caption text-md-subtitle-1">
                     <span>
                        Um Health Coach é o profissional que vai te ajudar a chegar onde você deseja. Ele te ajuda a
                        traçar um plano de metas viáveis e sustentáveis personalizadas, identificando suas barreiras
                        para o progresso, e auxiliando no processo de mudanças de estilo de vida.
                     </span>
                  </div>
               </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="expansion-painel-border">
               <v-expansion-panel-header class="white--text" color="#343434">
                  <template v-slot:actions>
                     <v-icon color="white" large> $expand </v-icon>
                  </template>
                  <div class="body-2 text-md-h6">Irei receber um Plano Alimentar/ Dieta?</div>
               </v-expansion-panel-header>
               <v-expansion-panel-content class="conteudo-expasion-papo-reto-maternidade" eager color="#343434">
                  <div class="white--text text-left text-caption text-md-subtitle-1">
                     <span> Não. Você irá receber orientações qualitativas sobre hábitos alimentares saudáveis. </span>
                  </div>
               </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="expansion-painel-border">
               <v-expansion-panel-header class="white--text" color="#343434">
                  <template v-slot:actions>
                     <v-icon color="white" large> $expand </v-icon>
                  </template>
                  <div class="body-2 text-md-h6">Irei receber um Plano de exercícios ?</div>
               </v-expansion-panel-header>
               <v-expansion-panel-content class="conteudo-expasion-papo-reto-maternidade" eager color="#343434">
                  <div class="white--text text-left text-caption text-md-subtitle-1">
                     <span> Não. Você irá receber orientações sobre prática de atividade física. </span>
                  </div>
               </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="expansion-painel-border">
               <v-expansion-panel-header class="white--text" color="#343434">
                  <template v-slot:actions>
                     <v-icon color="white" large> $expand </v-icon>
                  </template>
                  <div class="body-2 text-md-h6">As sessões de Health Coach são obrigatórias?</div>
               </v-expansion-panel-header>
               <v-expansion-panel-content class="conteudo-expasion-papo-reto-maternidade" eager color="#343434">
                  <div class="white--text text-left text-caption text-md-subtitle-1">
                     <span>
                        Sim. Para entrar no programa, você precisa passar por uma avaliação. Nessa avaliação você irá
                        escolher a principal questão que deseja melhorar e, assim, traçar, planos e metas para chegar em
                        resultados satisfatórios.
                     </span>
                  </div>
               </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="expansion-painel-border">
               <v-expansion-panel-header class="white--text" color="#343434">
                  <template v-slot:actions>
                     <v-icon color="white" large> $expand </v-icon>
                  </template>
                  <div class="body-2 text-md-h6">Preciso fazer algum exame para participar?</div>
               </v-expansion-panel-header>
               <v-expansion-panel-content class="conteudo-expasion-papo-reto-maternidade" eager color="#343434">
                  <div class="white--text text-left text-caption text-md-subtitle-1">
                     <span> Não precisa de nenhum exame prévio. </span>
                  </div>
               </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="expansion-painel-border">
               <v-expansion-panel-header class="white--text" color="#343434">
                  <template v-slot:actions>
                     <v-icon color="white" large> $expand </v-icon>
                  </template>
                  <div class="body-2 text-md-h6">Onde marco a sessão de Health Coach?</div>
               </v-expansion-panel-header>
               <v-expansion-panel-content class="conteudo-expasion-papo-reto-maternidade" eager color="#343434">
                  <div class="white--text text-left text-caption text-md-subtitle-1">
                     <span>
                        Entre na área
                        <a @click="goToConexa" class="white--text"><u>logada da plataforma Conexa Saúde</u></a>
                        , clique em Nutricionista, selecione 01 Health Coach e escolha um horário para sua sessão.
                     </span>
                  </div>
               </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="expansion-painel-border">
               <v-expansion-panel-header class="white--text" color="#343434">
                  <template v-slot:actions>
                     <v-icon color="white" large> $expand </v-icon>
                  </template>
                  <div class="body-2 text-md-h6">Como faço para participar?</div>
               </v-expansion-panel-header>
               <v-expansion-panel-content class="conteudo-expasion-papo-reto-maternidade" eager color="#343434">
                  <div class="white--text text-left text-caption text-md-subtitle-1">
                     <span>
                        Clique no botão inscrever-se na Página do programa Conexa Equilíbrio, preencha seus dados e
                        escolha um horário com o Health Coach na plataforma. Pronto! Ao finalizar a primeira sessão de
                        Avaliação você já estará no programa.
                     </span>
                  </div>
               </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="expansion-painel-border">
               <v-expansion-panel-header class="white--text" color="#343434">
                  <template v-slot:actions>
                     <v-icon color="white" large> $expand </v-icon>
                  </template>
                  <div class="body-2 text-md-h6">O que preciso para a primeira sessão de Health Coach?</div>
               </v-expansion-panel-header>
               <v-expansion-panel-content class="conteudo-expasion-papo-reto-maternidade" eager color="#343434">
                  <div class="white--text text-left text-caption text-md-subtitle-1">
                     <span> Animação e vontade de chegar na sua melhor versão. Com mais saúde e disposição. </span>
                  </div>
               </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="expansion-painel-border">
               <v-expansion-panel-header class="white--text" color="#343434">
                  <template v-slot:actions>
                     <v-icon color="white" large> $expand </v-icon>
                  </template>
                  <div class="body-2 text-md-h6">Como é feita a contagem dos pontos?</div>
               </v-expansion-panel-header>
               <v-expansion-panel-content class="conteudo-expasion-papo-reto-maternidade" eager color="#343434">
                  <div class="white--text text-left text-caption text-md-subtitle-1">
                     <span>
                        Iremos propor diversos desafios a você. Cada um deles com uma pontuação variada. Ao final, seus
                        pontos serão o somatório dos desafios cumpridos.
                     </span>
                  </div>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </div>
   </div>
</template>

<script>
export default {
   name: 'Duvidas',
   computed: {
      ehVersaoDesktop() {
         return this.$vuetify.breakpoint.mdAndUp;
      }
   },
   methods: {
      goToConexa() {
         window.open('https://www.conexasaude.com.br/', '_blank');
      }
   }
};
</script>
