<template>
   <div class="bg-color pb-12 pt-12 pt-md-0">
      <v-img src="@/assets/Depoimentos/titulo.svg" :width="ehVersaoDesktop ? 350 : 200" class="image-margin-auto" />
      <div class="secondary--text text-lg-h2 d-flex justify-space-around flex-wrap mt-12">
         <v-card color="white" rounded="lg" :width="ehVersaoDesktop ? 360 : 350" :height="ehVersaoDesktop ? 490 : 500">
            <v-card-title>
               <img src="@/assets/Depoimentos/comentario.svg" :width="80" style="margin: auto" />
            </v-card-title>
            <v-card-text class="text-center">
               <div class="mb-4">
                  "O Conexa Equilíbrio era o empurrãozinho que eu precisava para retomar minha vida saudável. Em apenas
                  15 dias, estou me sentindo muito melhor, menos ansiosa e já perdi alguns quilos. Voltei a andar de
                  bicicleta e fazer caminhadas diárias."
               </div>

               <div class="secondary--text mb-2">- Talita Leite -</div>
               <div>UX Writer - Grupo Conexa</div>
            </v-card-text>
         </v-card>
         <v-card
            color="white"
            rounded="lg"
            :width="ehVersaoDesktop ? 360 : 350"
            :height="ehVersaoDesktop ? 490 : 500"
            class="mt-4 mt-md-0"
         >
            <v-card-title>
               <img src="@/assets/Depoimentos/comentario.svg" :width="80" style="margin: auto" />
            </v-card-title>
            <v-card-text class="text-center">
               <div class="mb-4">
                  Eu decidi participar do Conexa Equilíbrio porque estava com problemas de sono, e fiquei muito
                  empolgada em saber que o programa seria completo, abrangendo todos os aspectos da nossa saúde. Além
                  disso, ainda que eu já tivesse hábitos saudáveis, eu encontrei o incentivo e o acompanhamento que não
                  tive em nenhum outro lugar. Com metas coerentes e estimulantes, aprendi muito com a health coach e com
                  os outros participantes. Está sendo uma experiência incrível. Consegui melhorar meu sono, passei a
                  beber mais água e a cuidar mais de mim mesma.
               </div>

               <div class="secondary--text mb-2">- Bárbara Ribeiro -</div>
               <div>Cargo - Empresa</div>
            </v-card-text>
         </v-card>
         <v-card
            color="white"
            rounded="lg"
            :width="ehVersaoDesktop ? 360 : 350"
            :height="ehVersaoDesktop ? 490 : 500"
            class="mt-4 mt-md-0"
         >
            <v-card-title>
               <img src="@/assets/Depoimentos/comentario.svg" :width="80" style="margin: auto" />
            </v-card-title>
            <v-card-text class="text-center">
               <div class="mb-4">
                  Quando entrei pro Conexa Equilíbrio, buscava apenas o emagrecimento, mas o programa oferece muito
                  mais, e vem me ajudando a ter hábitos muito mais saudáveis. Já era algo que eu vinha buscando para
                  minha saúde. E o estabelecimento de metas, exercícios e mudança na alimentação viraram parte da minha
                  rotina. Tenho me sentido muito melhor e mais disposta no meu dia a dia!
               </div>

               <div class="secondary--text mb-2">- Thays Torres -</div>
               <div>Cargo - Empresa</div>
            </v-card-text>
         </v-card>
      </div>
   </div>
</template>

<script>
export default {
   name: 'Depoimentos',
   computed: {
      ehVersaoDesktop() {
         return this.$vuetify.breakpoint.mdAndUp;
      }
   }
};
</script>
<style scoped>
.bg-color {
   background-color: #f2f2f2;
}
</style>
