export default {
   // TO DO VERIFICAR UMA DESSAS REGRAS ESTÁ ESTOURANDO ERRO NO CONSOLE
   obrigatorio: (v) => !!v || 'Campo obrigatório',
   email: (v) => {
      const pattern =
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(v) || 'Email inválido';
   },
   cpf: (v) => {
      const calcular = (cpf) => {
         if (typeof cpf !== 'string') return false;
         cpf = cpf.replace(/[\s.-]*/gim, '');
         if (
            !cpf ||
            cpf.length !== 11 ||
            cpf === '00000000000' ||
            cpf === '11111111111' ||
            cpf === '22222222222' ||
            cpf === '33333333333' ||
            cpf === '44444444444' ||
            cpf === '55555555555' ||
            cpf === '66666666666' ||
            cpf === '77777777777' ||
            cpf === '88888888888' ||
            cpf === '99999999999'
         ) {
            return false;
         }
         let soma = 0;
         let resto;
         for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
         resto = (soma * 10) % 11;
         if (resto === 10 || resto === 11) resto = 0;
         if (resto !== parseInt(cpf.substring(9, 10))) return false;
         soma = 0;
         for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
         resto = (soma * 10) % 11;
         if (resto === 10 || resto === 11) resto = 0;
         if (resto !== parseInt(cpf.substring(10, 11))) return false;
         return true;
      };
      const pattern = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}$/;
      return (pattern.test(v) && calcular(v)) || 'CPF Inválido';
   }
};
