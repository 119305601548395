<template>
   <div>
      <v-img src="@/assets/ConteudoPrograma/fundo_conteudo_programa.svg">
         <div
            class="secondary--text text-lg-h4 text-sm-body-2"
            :style="ehVersaoDesktop ? 'margin-top: 6%' : 'margin-top: 20%'"
         >
            <v-img
               src="@/assets/ConteudoPrograma/conteudo_programa.svg"
               :width="ehVersaoDesktop ? 600 : 380"
               style="margin: auto"
            />
         </div>
         <div style="margin-top: 10%">
            <div class="secondary--text text-lg-h2 d-flex justify-space-around flex-wrap mb-4">
               <v-card
                  color="white"
                  rounded="lg"
                  :width="ehVersaoDesktop ? 230 : 230"
                  :height="ehVersaoDesktop ? 200 : 230"
               >
                  <v-card-title>
                     <img
                        src="@/assets/ConteudoPrograma/sessao_online.svg"
                        :width="ehVersaoDesktop ? 120 : 150"
                        style="margin: auto"
                     />
                  </v-card-title>
                  <v-card-text class="secondary--text font-weight-bold text-center">
                     <span> Sessões on-line com Health Coach </span>
                  </v-card-text>
               </v-card>
               <v-card
                  color="white"
                  rounded="lg"
                  :width="ehVersaoDesktop ? 230 : 230"
                  :height="ehVersaoDesktop ? 200 : 240"
                  :class="ehVersaoDesktop ? '' : 'mt-8'"
               >
                  <v-card-title>
                     <img
                        src="@/assets/ConteudoPrograma/metas_inteligentes.svg"
                        :width="ehVersaoDesktop ? 110 : 150"
                        style="margin: auto"
                     />
                  </v-card-title>
                  <v-card-text class="secondary--text font-weight-bold text-center">
                     Estabelecimento de metas inteligentes
                  </v-card-text>
               </v-card>
               <v-card
                  color="white"
                  rounded="lg"
                  :width="ehVersaoDesktop ? 230 : 230"
                  :height="ehVersaoDesktop ? 200 : 240"
                  :class="ehVersaoDesktop ? '' : 'mt-8'"
               >
                  <v-card-title>
                     <img
                        src="@/assets/ConteudoPrograma/clock.svg"
                        :width="ehVersaoDesktop ? 70 : 100"
                        style="margin: auto"
                     />
                  </v-card-title>
                  <v-card-text class="secondary--text font-weight-bold text-center">
                     Monitoramento diário e suporte no desempenho
                  </v-card-text>
               </v-card>
               <v-card
                  color="white"
                  rounded="lg"
                  :width="ehVersaoDesktop ? 230 : 230"
                  :height="ehVersaoDesktop ? 200 : 220"
                  :class="ehVersaoDesktop ? '' : 'mt-8'"
               >
                  <v-card-title>
                     <img
                        src="@/assets/ConteudoPrograma/orientacoes.svg"
                        :width="ehVersaoDesktop ? 80 : 100"
                        style="margin: auto"
                     />
                  </v-card-title>
                  <v-card-text class="secondary--text font-weight-bold text-center">
                     Grupo de apoio e orientações
                  </v-card-text>
               </v-card>
            </div>
         </div>
      </v-img>
   </div>
</template>

<script>
export default {
   name: 'ConteudoPrograma',
   computed: {
      ehVersaoDesktop() {
         return this.$vuetify.breakpoint.mdAndUp;
      }
   }
};
</script>
